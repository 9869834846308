import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'
import { hasIntersectionObserver } from '../js/window-has';
export default class extends Controller {
    connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%",
            })
        }
    }
}
