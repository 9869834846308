export default {
    isMobile: function () {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 768
    },
    isDesktop: () => {
        return !this.isMobile()
    },
    getType: function () {
        return this.isMobile() ? 'mobile' : 'desktop'
    },
};

