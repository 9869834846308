import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'

import {hasIntersectionObserver} from '../../js/window-has';

export default class extends Controller {

    static values = {
        url: String,
        rendered: Boolean
    }

    async connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%"
            })
        } else {
            await this.appear()
        }
    }


    async appear() {

        if (this.renderedValue === true) {
            return;
        }

        this.renderedValue = true;

        await this._fetch();

    }

    async _fetch() {
        const response = await fetch(this.urlValue);

        // error
        if (!response?.ok) {
            return;
        }

        // this.element.classList.add('fade-enter-active', 'fade-enter-from');

        setTimeout(async () => {
            this.element.innerHTML = await response.text();
            this.element.classList.remove('section');
            // this.element.classList.add('fade-enter-to');
            this.element.removeAttribute('style');
        }, 500);
    }

}