let _intersectionObserver = () => {
    // Exit early if we're not running in a browser.
    if (typeof window !== 'object') {
        return _intersectionObserver = false;
    }

    // If all IntersectionObserver and IntersectionObserverEntry features are natively supported.
    if ('IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype) {

        // Minimal polyfill for Edge 15's lack of `isIntersecting`
        // See: https://github.com/w3c/IntersectionObserver/issues/211
        if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
            Object.defineProperty(window.IntersectionObserverEntry.prototype,
                'isIntersecting', {
                    get: function () {
                        return this.intersectionRatio > 0;
                    }
                });
        }

        return _intersectionObserver = true;
    }

    return _intersectionObserver = false;
};

export function hasIntersectionObserver () {
    if (typeof _intersectionObserver === 'function') {
        return _intersectionObserver();
    }

    return _intersectionObserver;
}

export function hasLocalStorage() {
    return 'localStorage' in window
}

export function hasSessionStorage() {
    return 'sessionStorage' in window
}
