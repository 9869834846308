import ChainStorage from '@solido/atlante-js/lib/Storage/ChainStorage';
import CookieStorage from '@solido/atlante-js/lib/Storage/CookieStorage';
import InMemoryStorage from '@solido/atlante-js/lib/Storage/InMemoryStorage';
import WebLocalStorage from '@solido/atlante-js/lib/Storage/WebLocalStorage';

const storage = new ChainStorage([
    new WebLocalStorage(),
    new CookieStorage(),
    new InMemoryStorage(),
]);

export default {
    async getWithExpiry(key) {
        const item = await storage.getItem(key);
        if (item.isHit) {
            return item.get();
        }

        return null;
    },
    async setWithExpiry(key, value, ttl) {
        const item = await storage.getItem(key);
        item.expiresAfter(ttl);
        item.set(value);

        await storage.save(item);
    }
};
