import {Controller} from '@hotwired/stimulus';
import {useClickOutside} from 'stimulus-use';
import modals from "../../js/modals";
import favouriteTeam from "../../js/favourite-team";
import throttle from 'lodash/throttle';
import {useIntersection} from 'stimulus-use';
import device from '../../js/device';
import {hasIntersectionObserver} from '../../js/window-has';

export default class extends Controller {


    menuRendered = false

    static targets = ['searchForm', 'submenuContent', 'menuToggle', 'submenuLabel', 'selectTeamLabel', 'selectTeamCheckbox', 'hideMenuContainer'];

    static values = {
        submenuId: {type: String, default: 'null'},
        urlTeamModal: String
    }

    connect() {
        useClickOutside(this, {element: this.searchFormTarget})

        if (device.getType() === 'desktop') {

            const menuCategory = document.getElementById("menu-category");
            const menuTeams = document.getElementById("menu-teams");
            const menuOptions = document.getElementById("menu-options");

            menuCategory.addEventListener("mouseover", (event) => {

                menuTeams.classList.add('!hidden')
                menuOptions.classList.add('!hidden')

            });

            menuCategory.addEventListener("mouseout", (event) => {

                menuTeams.classList.remove('!hidden')
                menuOptions.classList.remove('!hidden')

            });


            return;
        }

        /*
        * only for mobile device
        */
        this.headerSpacer = document.getElementById("main-header-hidden");

        /*
        * if header spacer exist enable floating menu
        */
        if (this.headerSpacer && hasIntersectionObserver()) {
            this.oldScrollY = window.scrollY;
            if (hasIntersectionObserver()) {
                useIntersection(this, {element: this.headerSpacer, rootMargin: '200px'})
            }

            this.scroll = throttle(this.scroll.bind(this), 100)
            window.addEventListener('scroll', this.scroll, {passive: true})
        }

    }

    scroll() {

        /*
        * clever behavior
        let cleverAD = document.getElementById("clever-76931-top-scroll");

        if (cleverAD != null) {
            this.element.classList.add('no-fix')
        }
        */


        // if spacer is visible, nothing happen
        if (this.isVisible()) {
            this.element.classList.remove('hide')
            return
        }

        if (this.oldScrollY < window.scrollY) {
            this.element.classList.add('hide')
        }

        if (this.oldScrollY > window.scrollY + 20) {
            this.element.classList.remove('hide');
        }

        this.oldScrollY = window.scrollY;
    }


    async selectTeam(e) {

        if (e.currentTarget.checked === true) {
            await modals.selectTeam(this.urlTeamModalValue).then((result) => {

                if (result.isDenied) {
                    favouriteTeam.setFavouriteModalDisplayDate();
                }
                if (result.isDismissed) {
                    favouriteTeam.setAskMeLater();
                }
            });

        } else {
            favouriteTeam.delete();
        }

        this.displaySelectTeamInMenu();
    }

    displaySelectTeamInMenu() {
        if (favouriteTeam.get()) {
            this.selectTeamLabelTarget.innerHTML = favouriteTeam.get().toUpperCase()
            this.selectTeamCheckboxTarget.checked = true
        } else {
            this.selectTeamLabelTarget.innerHTML = ''
            this.selectTeamCheckboxTarget.checked = false
        }
    }


    clickOutside(event) {
        this.element.classList.remove('show-search')
        this.searchFormTarget.querySelector('input').blur() // remove focus to search bar
        this.submenuIdValue = null;
    }

    showSearchForm() {
        this.menuToggleTarget.checked = false; // close menu if is opened
        document.body.classList.remove('overflow-hidden'); // remove no scroll to body
        this.element.classList.add('show-search')
        this.searchFormTarget.querySelector('input').focus() // add focus search bar
    }

    openMenu() {
        this.menuToggleTarget.checked = true;
    }

    closeMenu() {
        this.menuToggleTarget.checked = false;
    }

    submenuIdValueChanged(value) {
        if (value === 'null') {
            this.closeMenu();
            return
        }

        this.openMenu()
        this._openSubmenu(value)
        this.toggleMenu();
    }

    openSubmenu(event) {

        if (device.getType() === 'desktop') {
            this.openMenu()
            return;
        }

        let id = event.target.getAttribute('aria-controls');

        // behavior for mobile - desktop
        if (id) {
            this.submenuIdValue = id;
        } else {
            this.menuToggleTarget.checked = true;
            this.toggleMenu()
        }
    }

    _openSubmenu(value) {

        this.displaySelectTeamInMenu()

        // select submenu
        this.submenuContentTargets.forEach((e) => {
            if (e.id === this.submenuIdValue) {
                e.classList.remove('hidden')
            } else {
                e.classList.add('hidden')
            }
        })


        // select current controls
        this.submenuLabelTarget.querySelectorAll('li').forEach((e) => {
            if (e.getAttribute('aria-controls') === value) {
                e.classList.add('border-b-cm', 'text-light-black')
                e.classList.remove('border-b-color-2', 'text-gray2')
                e.setAttribute('aria-expanded', 'true')
            } else {
                e.classList.remove('border-b-cm', 'text-light-black')
                e.classList.add('border-b-color-2', 'text-gray2')
                e.setAttribute('aria-expanded', 'false')
            }
        })
    }

    async toggleMenu() {


        const mobileHeader = document.getElementById("mobile-header");
        if (mobileHeader){
        mobileHeader.style.zIndex = '0';

        }

        this.hideMenuContainerTarget.removeAttribute('hidden');
        this.displaySelectTeamInMenu();

        // fix desktop
        const pageContainer = document.getElementById("page-container");
        const body = document.body;

        if (this.menuToggleTarget.checked === true) {


            if (pageContainer) {
                pageContainer.classList.add('hidden');
            }

            body.classList.add('overflow-hidden');
            this.element.classList.add('fixed')


        } else {
            this.element.classList.remove('fixed')
            this.submenuIdValue = null;
            body.classList.remove('overflow-hidden');


            if (pageContainer) {
                pageContainer.classList.remove('hidden');
            }

        }
    }

}
