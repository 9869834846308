import {Controller} from '@hotwired/stimulus';
import {consola as l} from "consola/basic";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import favouriteTeam from "../js/favourite-team";
import modals from "../js/modals";
import Swal from "sweetalert2";

export default class extends Controller {

    vapIdKey = 'BAyYZ6voW3WmCjHWn9QLl_qlcQzswn5HYu6whm7Sov7VAk5ORelr10pE3-Z1DoZLKaHk72oz3tjp3sjRgs9s6rA';
    STORAGE_KEY_SUBSCRIBED = '_subscribe_notification';
    STORAGE_KEY_SERVICE_WORKER_INSTALLED = '_notification_service_worker';
    STORAGE_KEY_ASK_SUBSCRIBE = '_notification_ask_subscribe';


    static values = {
        urlSubscribe: String,
        urlModal: String
    }

    static targets = ['switch', 'switchLoader']

    async connect() {

        /*
        * If browser doesn't support notification
        */
        if (!this._notificationAvailable()) {
            this._disableSwitch()
            return
        }

        this._initializeApp();

        try {
            sessionStorage.getItem(this.STORAGE_KEY_SUBSCRIBED);
        } catch (e) {
            return;
        }

        /*
        * If browser doesn't register service worker
        */
        const serviceWorker = await this._registerServiceWorker()
        if (!serviceWorker) {
            this._disableSwitch()
        }

        /*
        * Check if user has grant and get subscribed team.
        * If not, disable switch.
        * This operation is done one time for session.
        */

        await this._checkSubscriptionsAndUpdateUiSwitch()

        l.info('Notification permission:', Notification.permission)

    }

    /*
     * This event is triggered by ask-subscribe-notification:ask
     */
    async askSubscribe() {
        // ask if: notification are available, favourite team is set, user is  not subscribed, and service worker is installed
        if (this._notificationAvailable() && favouriteTeam.get() && this._getSubscribeToStorage() === 'no' && this._getServiceWorkerInstalled() && !this._getAskSubscribe()) {
            this._setAskSubscribe()
            await this.subscribeNotificationByEvent();
        }
    }

    /*
    * This event is triggered by select-team-modal:team-selected when user select team in modal
    */
    async subscribeNotificationByEvent() {

        let confirm = false;

        await Swal.fire({
            title: 'Vuoi iscriverti alle notifiche sulla tua squadra preferita?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                confirm = true;
            }
        })


        if (confirm === true) {
            let subscription = await this._startSubscriptionLoopByTeam(favouriteTeam.get())

            if (subscription) {
                this._checkSwitch()
            }

        }

    }

    /*
    * Methods to check if user is subscribed to notification
    */
    async _checkSubscriptionsAndUpdateUiSwitch() {

        // no permission, user non subscribed
        if (!this._hasPermission()) {
            this._setNoSubscribeToStorage()
            this._resetSwitch()
            return
        }

        /*
        * check in localstorage
        */
        const isSubscribed = this._getSubscribeToStorage();
        if (isSubscribed) {
            if (isSubscribed === 'no') {
                this._resetSwitch();
            } else {
                this._checkSwitch();
            }
            return
        }

        /*
        * get token from firebase
        */
        const token = await this._getToken();

        if (token) {
            /*
            * check if token has subscriptions
            */
            const subscriptions = await this._getSubscriptions(token);

            if (subscriptions) {

                l.info('Notification subscribed to: ', subscriptions)

                // if not subscriptions (empty array) do nothing, switch is already unchecked
                if (subscriptions.length === 0) {
                    this._setNoSubscribeToStorage()
                    return;
                }

                subscriptions.forEach((subscription) => {
                    if (typeof subscription === 'string') {
                        // set switch to ON
                        this._setSubscribeToStorage(subscription)

                        this._checkSwitch();
                    }

                });


            } else {
                this._setNoSubscribeToStorage()
                this._resetSwitch();
            }

        } else {
            this._setNoSubscribeToStorage()
            this._resetSwitch();
        }
    }

    _setNoSubscribeToStorage() {
        sessionStorage.setItem(this.STORAGE_KEY_SUBSCRIBED, 'no')
    }

    _removeSubscribeInfoToStorage() {
        sessionStorage.removeItem(this.STORAGE_KEY_SUBSCRIBED)
    }

    _setSubscribeToStorage(team) {
        sessionStorage.setItem(this.STORAGE_KEY_SUBSCRIBED, team)
    }

    _setServiceWorkerInstalled() {
        sessionStorage.setItem(this.STORAGE_KEY_SERVICE_WORKER_INSTALLED, 'yes')
    }

    _setAskSubscribe() {
        sessionStorage.setItem(this.STORAGE_KEY_ASK_SUBSCRIBE, 'yes')
    }

    _getAskSubscribe() {
        return sessionStorage.getItem(this.STORAGE_KEY_ASK_SUBSCRIBE)
    }

    _getServiceWorkerInstalled() {
        return sessionStorage.getItem(this.STORAGE_KEY_SERVICE_WORKER_INSTALLED)
    }

    _getSubscribeToStorage() {
        return sessionStorage.getItem(this.STORAGE_KEY_SUBSCRIBED)
    }

    //

    async _startSubscriptionLoopByTeam(team) {
        const token = await this._requestPermissionsAndGetToken();

        l.info('Tonen is: ', token)

        if (token === null) {
            this._resetSwitch()
            await this._fireNotGrantPermission(1)
            return false;
        }

        // send token
        const response = await this._subscribeToTopic(token, team);

        // error
        if (!response?.ok) {
            await this._openServiceUnavailableModal()
            return false;
        }

        this._setSubscribeToStorage(team)
        await modals.toast('Iscritto correttamente a: ' + team, 'success')

        this._toggleSwitchLoader(false)

        return true;

    }


    async toggleNotification(e) {

        let team = favouriteTeam.get()

        // add loader
        this._toggleSwitchLoader(true);

        // switch ON
        if (e.currentTarget.checked === true) {

            /*
            * if team is not set open modal
            */
            if (team === null) {
                // open team modal and get team
                team = await this._openTeamModalAndGetTeam()

                // @TODO set team name in select team label (use event?)


                // if team is not set user leaved select team modal
                if (team === null) {
                    this._resetSwitch()
                    return;
                }

            }

            if (team) {
                await this._startSubscriptionLoopByTeam(team)
                return
            }

            // notification unsubscribe
        } else {

            let confirm = false;

            await Swal.fire({
                title: 'Ti vuoi disiscrivere da tutte le notifiche?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Si',
                denyButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirm = true;
                }
            })


            if (confirm === false) {
                this._toggleSwitchLoader(false);
                this._toggleSwitch(true);
                return
            }

            /*
            * User hasn't permission, in this case nothing to do
            * (I don't request token or open system modal)
            */
            if (!this._hasPermission()) {
                this._resetSwitch()
            }

            const token = await this._getToken();
            const response = await this._unsubscribeToAllTopic(token)

            // error
            if (!response?.ok) {
                this._toggleSwitchLoader(false);
                await this._openServiceUnavailableModal()
                return;
            }

            this._toggleSwitchLoader(false);
            this._removeSubscribeInfoToStorage()
            await modals.toast('Disiscritto correttamente a tutte le notifiche', 'success')
        }


        // in each case remove loader
        this._toggleSwitchLoader(false);
    }

    /*
    * App initialization
    */
    _initializeApp() {
        const firebaseConfig = {
            apiKey: "AIzaSyBKov5VElAj8LkQPWBvHRF9PGjf3X5XpR8",
            authDomain: "cm-msite-web-push.firebaseapp.com",
            databaseURL: "https://cm-msite-web-push.firebaseio.com",
            projectId: "cm-msite-web-push",
            storageBucket: "cm-msite-web-push.appspot.com",
            messagingSenderId: "873848959103",
            appId: "1:873848959103:web:14565d2b150da684",
            measurementId: "G-GJV0XJ1KMD"
        };

        this.messaging = getMessaging(initializeApp(firebaseConfig));
    }

    async _registerServiceWorker() {

        let success = async (registration) => {
            l.success("Registration service workder successful, scope is:", registration.scope)
            this._setServiceWorkerInstalled();
            return true
        };

        let error = async (error) => {
            l.success("Service worker registration failed, error:", error);
            return false;
        };

        return navigator.serviceWorker.register("/firebase-messaging-sw.js", {scope: "/firebase-cloud-messaging-push-scope"})
            .then(success)
            .catch(error);
    }

    /*
    * Switch behaviors and loader behaviors
    */
    _disableSwitch() {
        this.switchTarget.disabled = true
    }

    _toggleSwitch(check) {

        if (!this.hasSwitchTarget) {
            return
        }

        this.switchTarget.checked = Boolean(check);
    }

    _toggleSwitchLoader(show) {

        if (!this.hasSwitchLoaderTarget) {
            return
        }

        if (show) {
            this.switchLoaderTarget.removeAttribute('hidden')
        } else {
            this.switchLoaderTarget.setAttribute('hidden', 'hidden')
        }
    }

    _resetSwitch() {
        this._toggleSwitch(false);
        this._toggleSwitchLoader(false);
    }

    _checkSwitch() {
        this._toggleSwitch(true);
        this._toggleSwitchLoader(false);
    }

    //


    /*
    * Modals
    */

    async _openTeamModalAndGetTeam() {

        await modals.selectTeam(this.urlModalValue).then((result, other) => {

            if (result.isDenied) {
                favouriteTeam.setFavouriteModalDisplayDate();
            }

            if (result.isDismissed) {
                favouriteTeam.setAskMeLater();
            }
        });


        return favouriteTeam.get();
    }

    async _openServiceUnavailableModal() {
        await modals.toast('Servizio momentaneamente non disponibile, riprova più tardi', 'error')
    }


    async _requestPermission() {
        return await Notification.requestPermission()
            .then(async (permission) => {
                return permission === 'granted';
            });
    }

    _hasPermission() {
        return Notification.permission === 'granted';
    }

    _notificationAvailable() {
        return "Notification" in window && 'PushManager' in window && 'Storage' in window && 'serviceWorker' in navigator
    }

    async _requestPermissionsAndGetToken() {

        const permission = await this._requestPermission();

        if (!permission) {
            this._toggleSwitchLoader(false);
            this._toggleSwitch(false)
            return null
        }

        return await this._getToken();
    }

    async _getToken() {

        return await getToken(this.messaging, {vapidKey: this.vapIdKey}).then((currentToken) => {
            return currentToken ? currentToken : null;
        }).catch(async (err) => {
            return null
        });
    }

    async _fireNotGrantPermission(i = null) {
        await modals.toast('Il tuo browser ha le nofifiche blocate. Per abilitarle clicca sul lucchetto nella barra di ricerca e imposta le autorizzazioni ' + i, 'error', 10000)
    }


    /*
    * Server side method, get topic from token, delete all topic, register to topic
    */

    async _subscribeToTopic(token, topic) {
        return await fetch(this.urlSubscribeValue, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                topic: topic,
            })
        });
    }


    async _getSubscriptions(token) {
        const params = new URLSearchParams({token: token});

        const response = await fetch(`${this.urlSubscribeValue}?${params.toString()}`);

        if (!response?.ok) {
            return;
        }

        return await response.json()
    }

    async _unsubscribeToAllTopic(token) {
        return await fetch(this.urlSubscribeValue, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token
            })
        });
    }
}
