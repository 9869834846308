import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['slides', 'dots', 'prevButton', 'nextButton']
    static values = {
        background: {type: String, default: '!bg-cm'}
    }

    connect() {
        const dots = this.dotsTarget.children;
        const slides = this.slidesTarget.children;
        let c = 0;

        for (const slide of slides) {
            slide.dataset.count = c;

            if (c === 0) {
                slide.dataset.first = true;
            }


            if (c === (slides.length - 1)) {
                slide.dataset.last = true;
            }
            c++;
        }

        let i = 0;
        for (const dot of dots) {
            dot.dataset.count = i;
            i++
        }
    }


    disappear(e) {
        const element = e.target;
        const dots = this.dotsTarget.children;
        for (const dot of dots) {
            if (dot.dataset.count === element.dataset.count) {
                dot.classList.remove('!opacity-100')
                dot.classList.remove(this.backgroundValue)
            }
        }
    }

    appear(e) {

        const element = e.target;
        const dots = this.dotsTarget.children;

        if (element.dataset.first === "true") {
            this.prevButtonTarget.classList.add('opacity-65')
            this.nextButtonTarget.classList.remove('opacity-65')

        } else if (element.dataset.last === "true") {
            this.nextButtonTarget.classList.add('opacity-65')
            this.prevButtonTarget.classList.remove('opacity-65')


        } else {
            this.nextButtonTarget.classList.remove('opacity-65')
            this.prevButtonTarget.classList.remove('opacity-65')
        }

        for (const dot of dots) {
            if (dot.dataset.count === element.dataset.count) {
                dot.classList.add('!opacity-100');
                dot.classList.add(this.backgroundValue);
            }
        }
    }

    scrollForward(e) {
        this._scroll();
    }

    scrollBackwards(e) {
        this._scroll(-1);
    }

    _scroll(v = 1) {
        this.slidesTarget.scrollBy({
            left: this.slidesTarget.getBoundingClientRect().width * v,
            behavior: "smooth"
        });
    }
}