import ChainStorage from "@solido/atlante-js/lib/Storage/ChainStorage";
import CookieStorage from "@solido/atlante-js/lib/Storage/CookieStorage";
import InMemoryStorage from "@solido/atlante-js/lib/Storage/InMemoryStorage";
import WebLocalStorage from "@solido/atlante-js/lib/Storage/WebLocalStorage";

const wls = new WebLocalStorage();
wls._getItem = async function (key) {
    let item = localStorage.getItem(key);
    if (!item) {
        return undefined;
    }

    item = this.marshaller.unmarshall(item);

    return 'value' in item ? item.value : JSON.stringify(item);
};

wls.hasItem = async function (key) {
    let item;
    try {
        item = localStorage.getItem(key);
    } catch (e) {
        return false;
    }

    if (null === item) {
        return false;
    }

    try {
        item = this.marshaller.unmarshall(item);
        const expiry = item.expiry;
        return undefined === expiry || null === expiry || expiry >= new Date().getTime();
    } catch (e) {
        return false;
    }
}

const storage = new ChainStorage([
    wls,
    new CookieStorage(),
    new InMemoryStorage(),
]);

export default {
    localStorageKey: 'cm_user',
    async setUser(user) {
        const item = await storage.getItem(this.localStorageKey);
        item.set(user);
        await storage.save(item);
    },
    async deleteUser() {
        await storage.deleteItem(this.localStorageKey);
    },
    async isLogged() {
        let user = await this.getUser();
        if (!user) {
            return false;
        }

        return !!user.token;
    },
    async getUser() {
        const item = await storage.getItem(this.localStorageKey);
        if (!item.isHit) {
            return false;
        }

        return item.get();
    }
};

