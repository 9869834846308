export default {
    FAVOURITE_KEY: 'favorite-team',
    MODAL_FAVOURITE_KEY: 'modal_favourite',
    MODAL_DAY_THRESHOLD: 30,

    get() {
        try {
            return localStorage.getItem(this.FAVOURITE_KEY);
        } catch (e) {
            return undefined;
        }
    },
    set(team){
        try {
            localStorage.setItem(this.FAVOURITE_KEY, team);
        } catch (e) {
        }
    },
    delete(){
        try {
            localStorage.removeItem(this.FAVOURITE_KEY);
            localStorage.removeItem(this.MODAL_FAVOURITE_KEY);
        } catch (e) {
        }
    },
    setAskMeLater() {
        try {
            sessionStorage.setItem(this.MODAL_FAVOURITE_KEY, 'yes')
        } catch (e) {
        }
    },
    getAskMeLater() {
        try {
            return sessionStorage.getItem(this.MODAL_FAVOURITE_KEY)
        } catch (e) {
            return undefined;
        }
    },
    setFavouriteModalDisplayDate() {
        try {
            localStorage.setItem(this.MODAL_FAVOURITE_KEY, String(new Date().getTime()))
        } catch (e) {
        }
    },
    getFavouriteModalDisplayDate() {
        let favouriteDate;

        try {
            favouriteDate = localStorage.getItem(this.MODAL_FAVOURITE_KEY)
        } catch (e) {
            return null;
        }

        if (favouriteDate) {
            return new Date(parseInt(favouriteDate))
        }

        return null;
    },
    favouriteModalMustBeDisplayed() {
        if (this.getAskMeLater()) {
            return false;
        }

        let displayDate = this.getFavouriteModalDisplayDate();
        if (this.getFavouriteModalDisplayDate() === null) {
            return true;
        }

        let difference = new Date().getTime() - displayDate.getTime();
        let totalDays = Math.ceil(difference / (1000 * 3600 * 24))

        return totalDays >= this.MODAL_DAY_THRESHOLD;
    }
};

