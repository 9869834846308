import {Controller} from '@hotwired/stimulus';
import device from "../js/device";

export default class extends Controller {
    startY;
    startX;
    scrollLeft;
    scrollTop;
    isDown;

    connect() {

        if (device.getType() === 'mobile') {
            return;
        }

        this.element.addEventListener('mousedown', e => this.mouseIsDown(e));
        this.element.addEventListener('mouseup', e => this.mouseUp(e))
        this.element.addEventListener('mouseleave', e => this.mouseLeave(e));
        this.element.addEventListener('mousemove', e => this.mouseMove(e));

    }

    mouseIsDown(e) {
        this.element.classList.remove('snap-x')
        this.isDown = true;
        this.startY = e.pageY - this.element.offsetTop;
        this.startX = e.pageX - this.element.offsetLeft;
        this.scrollLeft = this.element.scrollLeft;
        this.scrollTop = this.element.scrollTop;
    }

    mouseUp(e) {
        this.isDown = false;
    }

    mouseLeave(e) {
        this.isDown = false;
    }

    mouseMove(e) {
        if (this.isDown) {
            e.preventDefault();
            //Move vertically
            const y = e.pageY - this.element.offsetTop;
            const walkY = y - this.startY;
            this.element.scrollTop = this.scrollTop - walkY;

            //Move Horizontally
            const x = e.pageX - this.element.offsetLeft;
            const walkX = x - this.startX;
            this.element.scrollLeft = this.scrollLeft - walkX;
        }
    }
}