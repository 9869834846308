import Swal from 'sweetalert2';
import favouriteTeam from "./favourite-team";

export default {

    async selectTeam(url) {

        const response = await fetch(url);
        const html = await response.text();

        return await Swal.fire({
            title: 'Seleziona la tua squadra preferita',
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: 'No grazie',
            cancelButtonText: 'Magari dopo',
            html: html,
            didOpen: () => {
                const teams = document.querySelectorAll('.teams-modal');

                teams.forEach(team => {
                    team.addEventListener('click', async () => {
                        favouriteTeam.set(team.dataset.slug)
                        Swal.close();

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        })

                        await Toast.fire({
                            icon: 'success',
                            title: 'Squadra selezionata correttamente:' + team.dataset.slug.toUpperCase()
                        })
                    });
                });
            }
        })

    },
    async toast(title, icon, timer= 3000) {

        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: timer,
            timerProgressBar: true
        })

        await Toast.fire({
            icon: icon,
            title: title
        })
    },
    async toastSuccess(title) {
        await this.toast(title, 'success');
    },
    close() {
        Swal.close();
    }


}
