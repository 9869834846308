import {Controller} from '@hotwired/stimulus';
import user from "../../js/user";

export default class extends Controller {
    async connect() {
        if (await user.isLogged()) {
            this.element.classList.add('logged')
        }
    }
}
