import * as Sentry from '@sentry/browser';
import { startStimulusApp } from '@symfony/stimulus-bridge';
import TextareaAutogrow from 'stimulus-textarea-autogrow'

import '@jymfony/util/lib/Platform';
import '@jymfony/util/lib/Async/Mutex';
import '@jymfony/util/lib/String/sprintf';
import '@jymfony/util/lib/is';
import '@jymfony/util/lib/mixins';
import '@jymfony/exceptions';

if (undefined === Error.captureStackTrace) {
    Error.captureStackTrace = function () {};
}

if (false && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        enableTracing: true,
        tracesSampleRate: 0.01,
        allowUrls: [
            /https:\/\/.*\.preprod\.calciomercato\.com/,
            /https:\/\/.*.calciomercato.com/,
        ],
    });
}

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.[jt]sx?$/
));

// memorize default handler
const defaultErrorHandler = app.handleError.bind(app);

// configure Sentry to log errors and prepend the default handler
const sentryErrorHandler = (error, message, detail = {}) => {
    defaultErrorHandler(error, message, detail);
    // Sentry.captureException(error);
};

// overwrite the default handler with our new composed handler
app.handleError = sentryErrorHandler;

// register any custom, 3rd party controllers here
// app.register('some_controller_name', SomeImportedController);
app.register('textarea-autogrow', TextareaAutogrow);
