import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use';
import device from '../js/device';
import {hasIntersectionObserver} from "../js/window-has";

export default class extends Controller {

    static values = {
        rendered: {type: Boolean, default: false},
        src: String,
        device: {type: String, default: 'mobile'},
    }

    connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%",
            })

            console.log('player init')

        } else {
            this.appear()
        }
    }

    appear() {
        if (this.renderedValue === true || (this.deviceValue !== 'auto' && device.getType() !== this.deviceValue)) {
            console.log('player exit')
            console.log('player render value', this.renderedValue)
            console.log('player device ', this.deviceValue)
            console.log('player device type ', device.getType())
            return;
        }

        /*
        *  Old vast implementation

        let videoType = 'homepage_ancorato';
        window.localStorage.setItem("tipo_video", videoType);

        let vast = new URL('https://pubads.g.doubleclick.net/gampad/ads?sz=640x360&iu=/35821442/calciomercato.com/tv/preroll&description_url=https%3A%2F%2Fwww.calciomercato.com%2F&tfcd=0&npa=0&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=');
        let permutiveSegsVideoAd = window.localStorage.getItem("_pdfps") ? JSON.parse(window.localStorage.getItem("_pdfps")) : '';

        vast.searchParams.append('cust_params', 'tipo_video=' + videoType + '&permutive=' + permutiveSegsVideoAd);

        window.localStorage.setItem("vast", vast.href);
        window.vast = vast.href;
        window.tipo_video = videoType;
        */


        const script = document.createElement("script");

        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", this.srcValue);

        this.element.appendChild(script);
        this.renderedValue = true;
    }
}