import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'
import {consola as l} from "consola/basic";
import {backOff} from "../js/backoff";
import device from '../js/device';
import {hasIntersectionObserver} from '../js/window-has';

export default class extends Controller {

    REFRESH_THRESHOLD_SECONDS = 35
    rendered = false

    static values = {
        adUnit: {type: String, default: null},
        elementId: {type: String, default: null},
        sizes: Array,
        device: {type: String, default: null},
        rendered: Boolean,
        allowRefresh: {type: Boolean, default: true},
        force: {type: Boolean, default: false},
        isSticky: {type: Boolean, default: false}
    }

    connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%",
            })

        } else {
            this.appear();
            return;
        }


        if (this.deviceValue !== 'auto' && device.getType() !== this.deviceValue) {
            l.info(this.elementIdValue, 'Not rendered: wrong device')
            return;
        }


        setTimeout(() => {
            if (this.forceValue || this.isVisible()) {

                backOff(() => {
                    let advAppear = this.appear()
                    if (!advAppear) {
                        throw new Error(`${this.adUnitValue} not ready. Try again...`);
                    }
                }, {
                    maxDelay: 20_000,
                    numOfAttempts: 10,
                    startingDelay: 500,
                }).catch(e => {
                    l.warn(e);
                });

            }
        }, 1_000)


    }

    appear() {
        // configs and library not set
        if ((this.deviceValue !== 'auto' && device.getType() !== this.deviceValue) || !this._checkConfig() || !this._checkGoogleTagLoaded()) {
            return false
        }

        const e = document.getElementById("has-adv");

        if (e && e.getAttribute('content') === 'false') {

            if (this.element.parentElement.classList.contains("adv")) {
                this.element.parentElement.remove();
            }
            this.rendered = true;
            return;
        }


        // Render Adv
        if (this.rendered === false) {
            this.rendered = true

            this._addEventListener()

            this.slot = this._render()

            if (this.allowRefreshValue) {
                this._checkSetTimoutCheckRefresh();
            }

            return true
        }

        if (this.rendered === true && this.allowRefreshValue) {
            if (this._refreshDateExpired()) {
                this._refresh();
            }
        }

        return false

    }

    _refreshDateExpired() {
        return ((Date.now() - this.renderDate) / 1_000) > this.REFRESH_THRESHOLD_SECONDS
    };

    _checkSetTimoutCheckRefresh() {

        setTimeout(() => {
            if (this.isVisible() && this._refreshDateExpired()) {
                this._refresh();
            }
            this._checkSetTimoutCheckRefresh();

        }, this.REFRESH_THRESHOLD_SECONDS * 1_000);

    }

    _refresh() {

        this.element.parentElement.classList.remove('no-background')

        let slot = this.slot
        googletag.cmd.push(function () {
            googletag.pubads().refresh([slot]);
        });

        this.renderDate = Date.now();

        if (window._debug()) {
            l.info('Slot refreshed:', slot.getSlotElementId())
        }
    }

    _render() {

        let id = this.elementIdValue;
        let adUnit = this.adUnitValue;
        let sizes = this.sizesValue;
        let slot

        if (window._debug()) {
            l.info('Ad unit name:', adUnit)
            l.info('Sizes:', sizes)
            l.info('Element id:', id)
        }
        const googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];

        googletag.cmd.push(function () {
            slot = googletag.defineSlot(adUnit, sizes, id);
            slot.addService(googletag.pubads());

        });

        googletag.cmd.push(function () {
            if (window._debug()) {
                l.info('Gt display:', id);
            }
            googletag.display(id);
        });

        googletag.pubads().refresh([slot]);




        this.renderDate = Date.now();
        return slot;
    }

    _checkConfig() {
        if (this.adUnitValue === null) {
            l.warn('[render] Adunit not exist:', this.elementIdValue);
            return false
        }

        if (this.sizesValue === null) {
            l.warn('[render] Sizes not exist:', this.sizesValue);
            return false
        }

        if (this.elementIdValue === null) {
            l.warn('[render] Id value not exist:', this.sizesValue);
            return false
        }

        return true;
    }

    _checkGoogleTagLoaded() {
        if (typeof window.googletag?.pubads !== 'function') {
            l.warn('[render] Googletag not loaded');
            return false;
        }

        window.googletag.enableServices();

        if (window.location.host !== 'www.calciomercato.com'){
            l.success('Add key value [environment -> staging]');
            window.googletag.pubads().setTargeting('environment', 'staging');
        }

        return true;
    }


    _addEventListener() {

        let callback = (event) => {

            // add close button in sticky
            if (!event.isEmpty && this.isStickyValue) {

                //this.element.parentElement.classList.add('bg-adv-sticky');

                setTimeout(() => {
                    let sibling = this.element.previousElementSibling;

                    if (!sibling?.classList.contains('cm-close')) {
                        this.element.insertAdjacentHTML('beforebegin', `<i class="cm-ico cm-close block text-[2.5rem] h-10 w-10 ml-2.5 rounded-[2.5rem] bg-white mr-auto opacity-50" onclick="this.parentElement.remove()"></i>`)
                    }
                }, 10_000)
            }

            if (window._debug()) {
                l.info('Slot', event.slot.getSlotElementId(), 'finished rendering.');
                l.info('Advertiser ID:', event.advertiserId);
                l.info('Campaign ID:', event.campaignId);
                l.info('Company IDs:', event.companyIds);
                l.info('Creative ID:', event.creativeId);
                l.info('Creative Template ID:', event.creativeTemplateId);
                l.info('Is backfill?:', event.isBackfill);
                l.info('Is empty?:', event.isEmpty);
                l.info('Label IDs:', event.labelIds);
                l.info('Line Item ID:', event.lineItemId);
                l.info('Size:', event.size);
                l.info('Slot content changed?', event.slotContentChanged);
                l.info('Source Agnostic Creative ID:', event.sourceAgnosticCreativeId);
                l.info('Source Agnostic Line Item ID:', event.sourceAgnosticLineItemId);
                l.info('Yield Group IDs:', event.yieldGroupIds);
            }
        }


        window.googletag.pubads().addEventListener('slotRenderEnded', callback);
    }

}
