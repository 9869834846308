import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'
import favouriteTeam from "../js/favourite-team";
import modals from "../js/modals";
import {hasIntersectionObserver} from '../js/window-has';

export default class extends Controller {

    static values = {
        url: String
    }

    async connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this)
        } else {
            await this.appear()
        }
    }

    async appear() {

        if (favouriteTeam.get() === null && favouriteTeam.favouriteModalMustBeDisplayed()) {
            await modals.selectTeam(this.urlValue).then((result) => {

                if (result.isDenied) {
                    favouriteTeam.setFavouriteModalDisplayDate();
                }

                if (result.isDismissed) {
                    favouriteTeam.setAskMeLater();
                }
            });


            if (favouriteTeam.get()) {
                this.dispatch('team-selected')
            }

        }
    }
}
