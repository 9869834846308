import {Controller} from '@hotwired/stimulus';
import user from "../../js/user";
import storage from "../../js/storage";
import {useIntersection} from 'stimulus-use'
import { hasIntersectionObserver } from '../../js/window-has';


export default class extends Controller {

    static targets = ['reply']
    storageKey = 'notification_reply_count'

    static values = {
        url: String
    }

    async connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this)
        }

        if (await user.isLogged()) {
            this.element.classList.add('logged')
        }

        await this.count();
    }


    async appear() {

        if (!await user.isLogged() || this.rendered) {
            return;
        }

        this.rendered = true

        this.element.innerHTML = '';

        const params = new URLSearchParams({
            userId: (await user.getUser()).id
        });

        const response = await fetch(`${this.urlValue}?${params.toString()}`);

        // error
        if (!response?.ok) {
            return;
        }

        this.element.insertAdjacentHTML('beforeend', await response.text())
        await this._updateToReadCounterByList()
    }


    async count() {

        if (!await user.isLogged()) {
            return true;
        }

        let c = await storage.getWithExpiry(this.storageKey)
        if (c) {
            this._updateToReadCounter(c)
            return true;
        }

        const params = new URLSearchParams({
            userId: (await user.getUser()).id
        });

        const response = await fetch(`${this.urlValue}/count?${params.toString()}`);

        if (!response?.ok) {
            return false;
        }

        c = await response.text()
        await storage.setWithExpiry(this.storageKey, c, 1000 * 60) // one minutes
        this._updateToReadCounter(c)

        return true;
    }

    async _updateToReadCounterByList() {

        let c = 0;
        this.replyTargets.forEach((e) => {
            if (!e.classList.contains('read')) {
                c++
            }
        });
        await storage.setWithExpiry(this.storageKey, String(c), 1000 * 60) // one minutes
        this._updateToReadCounter(c)
    }

    _updateToReadCounter(c) {

        const e = document.getElementById("comment-reply-counter");
        if (c > 0) {
            e.parentElement.style.display="block";
            e.removeAttribute('hidden');
            e.innerHTML = String(c);
        } else {
            e.innerHTML = '';
        }
    }

    async delete(e) {

        const element = e.currentTarget

        await this._commentReplyAction(e, "DELETE", () => {
            element.closest('.notification-list').remove();
        })
        await this._updateToReadCounterByList()

    }

    async deleteAll(e) {
        const element = document.getElementById("notification-list-container");

        await this._commentReplyActionAll(e, "DELETE", '/all', () => {
            element.innerHTML = '';
        })

        await this._updateToReadCounterByList()
    }

    async setReadAll(e) {

        await this._commentReplyActionAll(e, "PATCH", '/all', () => {
            this.replyTargets.forEach((e) => {
                e.classList.add('read');
            });
        })

        await this._updateToReadCounterByList()
    }

    async setRead(e) {

        const element = e.currentTarget

        await this._commentReplyAction(e, "PATCH", () => {
            element.closest('.notification-list').classList.add('!bg-color-4')
            element.remove()
        })
        await this._updateToReadCounterByList()
    }

    async _commentReplyAction(e, method, fn) {

        if (!await user.isLogged()) {
            return;
        }

        const params = new URLSearchParams({
            replyId: e.currentTarget.dataset.id,
            userId: (await user.getUser()).id
        });

        const response = await fetch(`${this.urlValue}?${params.toString()}`, {method: method});
        if (!response?.ok) {
            return;
        }

        fn();
    }

    async _commentReplyActionAll(e, method, path, fn) {

        if (!await user.isLogged()) {
            return;
        }

        const params = new URLSearchParams({
            userId: (await user.getUser()).id
        });

        const response = await fetch(`${this.urlValue}${path}?${params.toString()}`, {method: method});

        if (!response?.ok) {
            return;
        }

        fn();
    }
}
